const screenSizes = {
  xs: '480px',
  sm: '768px',
  md: '1024px',
  lg: '1200px',
};

export {
  screenSizes,
}
