import React from 'react';
import styled from 'styled-components';

import Container from './Container';

import { FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";
import eric from '../images/eric2.png';

import { highlightColor } from '../constants/css';
import { urls } from '../constants/urls';
import { screenSizes } from '../constants/screenSizes';

import '../App.css';

const Image = styled.img`
  width: 50%;
  height: auto;

  @media (max-width: ${screenSizes.xs}) {
    width: 100%; 
  }
`;

const Text = styled.div`
  color: white;
  padding: 60px;
  width: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: ${screenSizes.xs}) {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
`;

const Title = styled.h1`
  margin-bottom: 0px;
`;

const Name = styled.span`
  color: #1ad1ed;
`;

const Subtitle = styled.h2`
  margin-bottom: 30px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: inline-block;
`;

const Icon = styled.button`
  font-size: 44px;
  padding: none;
  background: none;
  user-select: none;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;

  &:hover {
    color: ${highlightColor};
    cursor: pointer;
  }
`;

const Header = () => {
  const openInNewTab = (url) => {
    const newWindow = window.open(urls[url], '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  };

  return (
    <Container>
      <Image src={eric} alt="Eric" />
      <Text>
        <Title>Hi, I'm <Name>Eric Eakin</Name></Title>
        <Subtitle>Full-Stack Software Engineer</Subtitle>
        <IconContainer>
          <IconWrapper>
            <Icon>
              <FaLinkedin onClick={() => openInNewTab('LinkedIn')} />
            </Icon>
          </IconWrapper>
          <IconWrapper>
            <Icon>
              <FaGithub onClick={() => openInNewTab('Github')} />
            </Icon>
          </IconWrapper>
          <IconWrapper>
            <Icon>
              <FaInstagram onClick={() => openInNewTab('Instagram')} />
            </Icon>
          </IconWrapper>
        </IconContainer>
      </Text>
    </Container>
  );
}

export default Header;