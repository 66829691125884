import React from 'react';
import styled from 'styled-components';

import { backgroundColor, backgroundColor2 } from '../constants/css';
import { screenSizes } from '../constants/screenSizes';

const Container = styled.div`
  background-color: ${backgroundColor};
  background-image: linear-gradient(${backgroundColor2} 10%, ${backgroundColor});
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${screenSizes.md}) {
    padding: 20px;
  }

  @media (max-width: ${screenSizes.xs}) {
    padding: 5px;
  }
`;

const Content = styled.div`
  max-width: 1080px;
  width: 100%;
  padding: 90px 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${screenSizes.sm}) {
    padding: 30px;
  }

  @media (max-width: ${screenSizes.xs}) {
    flex-direction: column;
    padding: 10px;
  }
`;

const Main = ({ children }) => {
  return (
    <Container>
      <Content>
        {children}
      </Content>
    </Container>
  );
}

export default Main;