import React from 'react';
import styled from 'styled-components';

import Project from './Project';

import { screenSizes } from '../constants/screenSizes';
import { projects } from '../constants/projects';
import { projectColors } from '../constants/css';


const Container = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${screenSizes.md}) {
    padding: 20px 0 0;
  }

  @media (max-width: ${screenSizes.xs}) {
    padding: 5px;
  }
`;

const Content = styled.div`
  max-width: 1080px;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${screenSizes.md}) {
    padding: 0;
  }

  @media (max-width: ${screenSizes.xs}) {
    flex-direction: column;
    padding: 10px;
  }
`;

const Title = styled.h1`
  margin-bottom: 0px;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  
  &:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 50%;
    padding-top: 20px;
    border-bottom: 1px solid black;
  }
`;

const ProjectsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${screenSizes.xs}) {
    flex-direction: column;
    margin: 10px;
  }
`;

const Projects = () => {
  return (
    <Container>
      <Content>
        <Title>Projects</Title>
        <ProjectsContainer>
          {projects.map((project, i) => 
            <Project 
              {...project}
              key={project.title} 
              color={projectColors[i]}
            />
          )}
        </ProjectsContainer>
      </Content>
    </Container>
  );
}

export default Projects;