import React from 'react';
import styled from 'styled-components';

import Header from './components/Header';
import Projects from './components/Projects';
import curve from './images/Curve.svg';

import './App.css';

const Main = styled.div`
  position: relative;
  overflow-x: hidden;
  background-color: #f5f5f5;
`;

const Curve = styled.div`
  background: no-repeat center center url(${curve});
  background-size: cover;
  user-select: none;
  margin-top: -2px;
  height: calc(100vw * 45/500);
`;

function App() {
  return (
    <Main>
      <Header />
      <Curve />

      <Projects />
    </Main>
  );
}

export default App;
