import React from 'react';
import styled from 'styled-components';

import { screenSizes } from '../constants/screenSizes';

  // background-color: ${({ color }) => color};
const Container = styled.div`
  width: calc(50% - 36px);
  padding: 6px 8px;
  margin: 10px;
  border-radius: 2px;
  background-image: linear-gradient(${({ color }) => color[0]} 10%, ${({ color }) => color[1]});
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .4);

  @media (max-width: ${screenSizes.md}) {
    width: calc(100% - 34px);
    padding: 6px;
    margin: 10px;
  }

  @media (max-width: ${screenSizes.xs}) {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
  }
`;

const Content = styled.div`
  color: white;
  padding: 10px;
`;

const CoverImage = styled.div`
  background: no-repeat center center url(${({ coverImage }) => coverImage});
  background-size: cover;
  height: 260px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, .4);

  @media (max-width: ${screenSizes.md}) {
    height: 350px;
  }

  @media (max-width: ${screenSizes.xs}) {
    height: 180px;
  }
`;

const Title = styled.h3`
  padding-top: 10px;
  border-top: 1px solid #ccc;
  margin: 15px 0 5px;
`;

const Description = styled.h4`
  font-style: italic;
  margin-bottom: 5px;
`;

const Tags = styled.h5`
  font-weight: bold;
`;


const Project = ({ title, description, coverImage, tags, color }) => {
  return (
    <Container color={color}>
      <Content>
        <CoverImage coverImage={coverImage} />
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Tags>{tags.join(', ')}</Tags>
      </Content>
    </Container>
  );
}

export default Project;