const backgroundColor = '#252934';
const backgroundColor2 = '#3a4052';
const highlightColor = '#11c6f0';

const projectColors = [
  ['#20804b', '#155130'], // light color first
  ['#5da9ba', '#4D8A98'], 
  ['#752b5a', '#441934'], 
  ['#a84639', '#84372D'], 
  ['#c98612', '#A87010'], 
  ['#7c659c', '#5A4A71']
];

export {
  backgroundColor,
  backgroundColor2,
  highlightColor,
  projectColors,
}
