import hongyun from '../images/hongyun.png';
import jarvis from '../images/jarvis.png';
import mathwizards from '../images/mathwizards.jpg';
import smashball from '../images/smashball.jpeg';
import galaxyGuardian from '../images/galaxyGuardian.jpg';
import quickbooks from '../images/quickbooks.png';
import quickbooks2 from '../images/quickbooks2.png';

const projects = [
  {
    title: 'Quickbooks Online Payroll',
    description: 'Employee setup',
    coverImage: quickbooks2,
    tags: ['React', 'Apollo', 'GraphQL', 'Java', 'Spring Boot'],
  },
  {
    title: 'Jarvis',
    description: 'Classroom management application',
    coverImage: jarvis,
    tags: ['React', 'Express', 'Node', 'Firebase'],
  },
  {
    title: 'Galaxy Guardian',
    description: 'Animated space game',
    coverImage: galaxyGuardian,
    tags: ['HTML5', 'Canvas'],
  },
  {
    title: 'Math Wizards',
    description: 'Animated math game',
    coverImage: mathwizards,
    tags: ['HTML5', 'Canvas'],
  },
  {
    title: 'Smash Ball Brawl',
    description: 'Physics based multiplayer game',
    coverImage: smashball,
    tags: ['React', 'ThreeJS', 'CannonJS', 'Postgres', 'Express', 'Node', 'HTML5', 'Canvas'],
  }
];

export {
  projects,
}